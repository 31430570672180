import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import TopImage from "../components/top-image"
import SEO from '../components/seo'
import DynamicColor from '../components/dynamic-color'
import "../styles/information.scss"
const DailySchedule = (props) => {
  return (
    <div key={props.day} style={{ display: "flex", justifyContent: "flex-start", marginBottom: '6px' }}>
      <div style={{ flexGrow: 1, flexBasis: "auto", justifyContent: 'flex-end', display: 'flex' }}>
        <strong>{props.day}</strong>
      </div>
      <div style={{ flexGrow: 2, flexBasis: "auto", alignItems: 'flex-start', display: 'flex', marginLeft: '20px' }}>
        {props.dailySchedule.map((schedule, idx) => (
          <span key={idx}>{schedule}&nbsp;&nbsp;&nbsp;</span>
        ))}
      </div>
    </div>
  )
}

export default function Information({ data }) {
  const schedules = data.restaurant.settings.schedule
  return (
    <Layout data={data}>
      <SEO title="Qui sommes nous ?" />
      <DynamicColor data={data} />
      <div className="information-page">
        <TopImage
          title="Information"
          images={data.restaurant.websiteSettings?.restoImages || data.restaurant.websiteSettings?.dishImages || []}
        />
        <div className="information">
          <div className="left">
            <h2>Qui sommes nous ?</h2>
            <div dangerouslySetInnerHTML={{ __html: data.restaurant.websiteSettings?.introMessage }} />
          </div>
          <div className="right">
            <h2>Horraire d'ouverture</h2><br />
            <DailySchedule
              day="Lundi"
              dailySchedule={schedules[0].dailySchedule}
            />
            <DailySchedule
              day="Mardi"
              dailySchedule={schedules[1].dailySchedule}
            />
            <DailySchedule
              day="Mercredi"
              dailySchedule={schedules[2].dailySchedule}
            />
            <DailySchedule
              day="Jeudi"
              dailySchedule={schedules[3].dailySchedule}
            />
            <DailySchedule
              day="Vendredi"
              dailySchedule={schedules[4].dailySchedule}
            />
            <DailySchedule
              day="Samedi"
              dailySchedule={schedules[5].dailySchedule}
            />
            <DailySchedule
              day="Dimanche"
              dailySchedule={schedules[6].dailySchedule}
            />
          </div>

        </div>
      </div>
      <iframe title="google map" className="map" height="450" frameBorder="0" src={`https://www.google.com/maps/embed/v1/place?q=place_id:${data.restaurant.websiteSettings.googlePlaceId}&key=${process.env.GATSBY_GOOGLE_EMBED_API_KEY}`} allowFullScreen></iframe>
    </Layout >
  )
}


export const query = graphql`
  query PageQuery2 ($restoId: String!) {
      restaurant(id: {eq: $restoId}) {
      urlName
      informations {
        businessType
        address
        postalCode
        city
        name
        phone
        restoId
      }
      settings {
        color
        takeaway
        delivery
        schedule {
          dailySchedule
        }
      }
      websiteSettings {
        reservation
        googlePlaceId
        googleMapsUrl
        deliverooUrl
        uberEatsUrl
        logo {
          path
        }
        restoImages {
          path
        }
        linkFacebook
        linkInstagram
        linkYelp
        linkTwitter
        linkFoursquare
        linkTripadvisor
        introMessage
        popupMessage
        color
        colorSecond
        colorBackground
      }
    }
  }
`